import { useState, useEffect } from "react";
import searchablePages from "@/config/searchablePages";
import PageLink from "@/components/common/PageLink";

export default function Modal(props) {
  const { state, setState, searchRef } = props;
  const [localState, setLocalState] = useState({
    searchText: "",
    results: []
  });

  useEffect(() => {
    if (state.fuseIndex) {
      setLocalState({
        ...localState,
        results: state.fuseIndex.search(localState.searchText)
      });
    }
  }, [localState.searchText, state.fuseIndex]);

  return (
    <div className="fixed z-20 inset-0 overflow-y-auto w-screen hidden sm:block">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/*
Background overlay, show/hide based on modal state.

Entering: "ease-out duration-300"
  From: "opacity-0"
  To: "opacity-100"
Leaving: "ease-in duration-200"
  From: "opacity-100"
  To: "opacity-0"
    */}
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            className="absolute inset-0 bg-gray-600 opacity-50 cursor-pointer"
            onClick={() =>
              setState(state => ({ ...state, searchModalOpen: false }))
            }
          />
        </div>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          ​
        </span>
        {/*
Modal panel, show/hide based on modal state.

Entering: "ease-out duration-300"
  From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  To: "opacity-100 translate-y-0 sm:scale-100"
Leaving: "ease-in duration-200"
  From: "opacity-100 translate-y-0 sm:scale-100"
  To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    */}
        <div
          className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full divide-y divide-gray-200 px-8 py-4"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="py-8 flex items-center h-8">
            <svg
              className="h-6 w-6 text-primary-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            <input
              ref={searchRef}
              type="text"
              className="mx-2 border-none focus:outline-none focus:border-none focus:shadow-none focus:ring-transparent  placeholder-gray-500 font-medium text-base flex-1"
              placeholder="Search for a page"
              value={localState.searchText}
              onChange={e =>
                setLocalState({ ...localState, searchText: e.target.value })
              }
            />
            <button
              onClick={() =>
                setState({
                  ...state,
                  searchModalOpen: false
                })
              }
            >
              <svg
                className="w-6 h-6 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="py-6 pr-4 pl-1 overflow-y-auto h-80 flex flex-col gap-2">
            {(
              (localState.results.length > 0 &&
                localState.results
                  .sort((a, b) => a.score - b.score)
                  .map(v => v.item)) ||
              searchablePages
            ).map(v => (
              <PageLink
                href={v.url}
                className="flex py-4 px-6 bg-gray-100 group hover:bg-primary-600 rounded-lg items-center gap-6"
                key={v.title}
                onClick={() => {
                  setState(state => ({ ...state, searchModalOpen: false }));
                }}
              >
                <svg
                  className="w-8 h-8 text-gray-600 group-hover:text-primary-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
                <div className="flex flex-col flex-1">
                  <div className="font-medium text-gray-500 text-xs group-hover:text-primary-200">
                    {v.breadcrumbs.join(" / ")}
                  </div>
                  <div className="text-base font-bold text-gray-900 group-hover:text-white">
                    {v.title}
                  </div>
                  <div className="text-sm text-gray-700 group-hover:text-primary-100">
                    {v.description}
                  </div>
                </div>
              </PageLink>
            ))}
          </div>
          <div className="px-4 py-4 sm:px-6">
            {/* Content goes here */}
            {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
          </div>
        </div>
      </div>
    </div>
  );
}
