import { useState, useRef, useEffect } from "react";
import PageLink from "@/components/common/PageLink";
import { useRouter } from "next/router";
import Link from "next/link";
import Logo from "@/components/brand/Logo";
import Fuse from "fuse.js";

import LandingMenu from "@/components/layouts/landing/menu/LandingMenu";
import LandingMobileMenu from "@/components/layouts/landing/menu/LandingMobileMenu";
import SearchModal from "@/components/layouts/landing/SearchModal";
import useUserProfile from "@/scripts/hooks/useUserProfile";

import searchablePages from "@/config/searchablePages";

export default function NavBar() {
  const { user } = useUserProfile();
  const loginButtonLink = user ? "/app" : "/login";
  const signUpButtonLinkReseller = user ? "/app" : "/signup/reseller";
  const signUpButtonLinkSupplier = user ? "/app" : "/signup/supplier";
  const [state, setState] = useState({
    solutionsMenuOpen: false,
    moreMenuOpen: false,
    mobileMenuOpen: false,
    searchModalOpen: false,
    fuseIndex: null,
    loginDropDown: false,
    signUpDropDown: false
  });
  const router = useRouter();
  const previewPageBoolean =
    router.pathname === "/product-catalogue" ? true : false;
  const searchRef = useRef(null);

  useEffect(() => {
    if (state.searchModalOpen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [state.searchModalOpen, searchRef.current]);

  useEffect(() => {
    const options = {
      includeScore: true,
      keys: [
        {
          name: "title",
          weight: 0.5
        },
        {
          name: "breadcrumbs",
          weight: 0.3
        },
        { name: "description", weight: 0.2 }
      ]
    };
    const fuseIndex = new Fuse(searchablePages, options);
    setState({
      ...state,
      fuseIndex
    });
  }, []);

  return (
    <div className="bg-white h-18  w-screen ">
      <div className="max-w-screen-lg xl:max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="lg:w-0 lg:flex-1 flex">
            <Link href={"/"}>
              <a
                className="h-8 relative"
                onClick={() =>
                  setState({
                    ...state,
                    mobileMenuOpen: false,
                    solutionsMenuOpen: false,
                    moreMenuOpen: false
                  })
                }
              >
                <Logo className="h-9" />
              </a>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: !state.mobileMenuOpen
                })
              }
            >
              {/* Heroicon name: menu */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          {previewPageBoolean ? (
            <div
              className="hidden md:flex text-center
            "
            >
              <p>
                Welcome to Twurs Product Catalogue. Sign up as a
                <Link href={signUpButtonLinkSupplier}>
                  <a className="inline px-2 py-2 underline text-md text-bold text-red-500 ">
                    Supplier
                  </a>
                </Link>
                or
                <Link href={signUpButtonLinkReseller}>
                  <a className="inline px-2 py-2 underline text-md text-bold text-red-500">
                    Reseller
                  </a>
                </Link>
                today!
              </p>
            </div>
          ) : (
            <LandingMenu {...{ state, setState }} />
          )}
          <div className="hidden md:flex items-center justify-end space-x-6 md:flex-1 lg:w-0">
            <div
              className="flex flex-row w-32"
              onClick={e =>
                setState({
                  ...state,
                  loginDropDown: !state.loginDropDown,
                  signUpDropDown: false
                })
              }
            >
              <div className="w-full sm:w-full flex flex-row text-center items-center bg-red-700 text-white cursor-pointer   leading-6 font-semibold py-2 px-4 border border-gray-200 rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="28px"
                  viewBox="0 0 24 24"
                  width="28px"
                  fill="#FFFFFF"
                >
                  <path
                    d="M0 0h24v24H0V0z"
                    className="hidden flex-shrink-0 ml-2 h-5 w-5 text-gray-400"
                  />
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 
                  4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 
                  3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"
                  />
                </svg>
                <a className="flex ml-2 text-md"> Login </a>
              </div>

              {state.loginDropDown && (
                <div
                  className="z-50 absolute mt-12 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <Link href={loginButtonLink}>
                    <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Supplier
                    </a>
                  </Link>
                  <Link href={loginButtonLink}>
                    <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Reseller
                    </a>
                  </Link>
                </div>
              )}
            </div>
            {previewPageBoolean ? (
              <></>
            ) : (
              <div
                className="flex cursor-pointer"
                onClick={e =>
                  setState({
                    ...state,
                    loginDropDown: false,
                    signUpDropDown: !state.signUpDropDown
                  })
                }
              >
                <a className="whitespace-no-wrap text-md leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 ">
                  Sign Up
                </a>
                <svg
                  className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                {state.signUpDropDown && (
                  <div
                    className="z-50 absolute mt-8 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <Link href={signUpButtonLinkSupplier}>
                      <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Supplier
                      </a>
                    </Link>
                    <Link href={signUpButtonLinkReseller}>
                      <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Reseller
                      </a>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {state.mobileMenuOpen && (
        <div className="absolute top-0 inset-x-0 p-2 z-50 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg">
            <LandingMobileMenu {...{ state, setState }} />
          </div>
        </div>
      )}
      {state.searchModalOpen && (
        <SearchModal {...{ state, setState, searchRef }} />
      )}
    </div>
  );
}
