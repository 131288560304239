import { RecoilRoot } from "recoil";
import { FuegoProvider } from "@nandorojo/swr-firestore";
import { Fuego } from "@/scripts/fuego";

import FirebaseProvider from "@/components/wrappers/FirebaseProvider";
import BaseHeaders from "@/components/wrappers/BaseHeaders";
import RedirectWrapper from "@/components/wrappers/RedirectWrapper";
import LayoutWrapper from "@/components/wrappers/LayoutWrapper";

import firebaseConfig from "@/config/firebaseConfig";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "@/scripts/apolloClient";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "@/styles/globals.css";
import "@/styles/calendar.css";
import "@/styles/swiperStyles.css";
import "react-bnb-gallery/dist/style.css";
import Head from "next/head";

const fuego = new Fuego(firebaseConfig);

Sentry.init({
  dsn: "https://b063fecf409e4f6ba025d408fb40e86a@o561228.ingest.sentry.io/5697863",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: `twurs-web@${new Date().toISOString().slice(0, 10)}`
});

function App(props) {
  const { Component, pageProps } = props;

  return (
    <>
      <Head>
        <meta
          key="twitter:title"
          name="twitter:title"
          content="Twurs | Travel Management and distribution systems for tour and hospitality operators"
        />
        <meta
          key="og:title"
          property="og:title"
          content="Twurs | Travel Management and distribution systems for tour and hospitality operators"
        />
        <meta
          name="title"
          content="Twurs | Travel Management and distribution systems for tour and
          hospitality operators"
        />
        <meta
          name="description"
          content="Feature and sell tour and hospitality products with a single, automated connection, optimized for travelers and agents - complete software and backend solution for dynamic pricing, yield management, manifest, supplier and user management."
        />
        <title>
          Twurs | Travel Management and distribution systems for tour and
          hospitality operators
        </title>
      </Head>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <FuegoProvider fuego={fuego}>
          <FirebaseProvider>
            <ApolloProvider client={apolloClient}>
              <RecoilRoot>
                <RedirectWrapper>
                  <LayoutWrapper>
                    <BaseHeaders />
                    <Component {...pageProps} />
                  </LayoutWrapper>
                </RedirectWrapper>
              </RecoilRoot>
            </ApolloProvider>
          </FirebaseProvider>
        </FuegoProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default Sentry.withProfiler(App);
