export default {
  apiKey: "AIzaSyB5TlkxCAYyuyvyICxRzvTr89q4zN99Bk4",
  authDomain: "twurs-com.firebaseapp.com",
  databaseURL: "https://twurs-com.firebaseio.com",
  projectId: "twurs-com",
  storageBucket: "twurs-com.appspot.com",
  messagingSenderId: "837055818154",
  appId: "1:837055818154:web:fc0ab4bd4fd971b21ba3d3",
  measurementId: "G-S83KZTWV2D"
};
