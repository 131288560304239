import NavBar from "@/components/layouts/landing/NavBar";
import Footer from "@/components/layouts/landing/Footer";
import CookieNotice from "@/components/layouts/landing/CookieNotice";
import { useEffect, useRef } from "react";
import { useRouter } from "next/router";

export default function LandingLayout(props) {
  const { children } = props;
  const topRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    if (process.browser && window && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [router.asPath, topRef]);

  return (
    <>
      <div ref={topRef} />
      <NavBar />
      {/* <main className="max-w-screen-lg xl:max-w-screen-xl mx-auto min-h-screen ">
        {children}
      </main> */}
      <main className="mx-auto min-h-screen ">{children}</main>
      <Footer />
      <CookieNotice />
    </>
  );
}
