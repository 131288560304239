import NavBar from "@/components/layouts/shop/NavBar";
import Footer from "@/components/layouts/landing/Footer";
import CookieNotice from "@/components/layouts/landing/CookieNotice";
import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import useUserProfile from "@/scripts/hooks/useUserProfile";
import LoginPage from "@/pages/login";

export default function ShopLayout(props) {
  const { children } = props;
  const topRef = useRef(null);
  const router = useRouter();
  const { user } = useUserProfile();

  useEffect(() => {
    if (process.browser && window && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [router.asPath, topRef]);

  return (
    <>
      <div ref={topRef} />
      <NavBar />
      <main className="min-h-screen ">{children}</main>
      <Footer />
      <CookieNotice />
    </>
  );
}
