/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { useRouter } from "next/router";
import { useState, useRef, useEffect } from "react";
import Link from "next/link";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Logo from "@/components/brand/Logo";
import useUserProfile from "@/scripts/hooks/useUserProfile";
import { fuego, useCollection, useDocument } from "@nandorojo/swr-firestore";
import { round } from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PortalNavbar() {
  const router = useRouter();

  const { user } = useUserProfile();

  const [state, setState] = useState({
    unreadMessages: false
  });

  const { data: chatData } = useCollection<any>(`chats`, {
    listen: true
  });

  const { data: walletsData } = useDocument<any>(`wallets/${user?.email}`, {
    listen: true
  });

  useEffect(() => {
    const unreadMessages = chatData?.some(chat => chat?.unreadMessages);
    setState(state => ({ ...state, unreadMessages }));
  }, [chatData]);

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <Link href="/app/portal">
                  <div className="flex-shrink-0 flex items-center cursor-pointer">
                    <Logo className="h-6" />
                    <span className="ml-3 mt-1 text-gray-400">|</span>
                    <span className="ml-3 text-sm text-primary-700 mt-1 font-medium">
                      Reseller Portal
                    </span>
                  </div>
                </Link>
              </div>
              <div className="hidden  lg:ml-6 lg:flex lg:items-center">
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8 mr-8">
                  <Link href="/app/portal/orders">
                    {/* <a
                      href="#"
                      className=" bg-gray-800 text-white  hover:bg-gray-900 inline-flex items-center py-2 px-3 rounded-sm  text-xs font-medium"
                    >
                      Bookings
                    </a> */}
                    <a
                      href="#"
                      onClick={() => {
                        setState(state => ({
                          ...state,
                          unreadMessages: false
                        }));
                      }}
                      className=" text-gray-800  hover:text-gray-900 inline-flex items-center  px-1 pt-1  text-sm font-medium"
                    >
                      Bookings
                      <div
                        className={`${
                          state.unreadMessages
                            ? "visible text-xs w-2 h-2 ml-1 mb-2 bg-primary-600 rounded-full text-center text-white"
                            : "hidden"
                        }`}
                      >
                        .
                      </div>
                    </a>
                  </Link>

                  <Link href="/app/portal/transactions">
                    <a
                      href="#"
                      className=" text-gray-800  hover:text-gray-900 inline-flex items-center  px-1 pt-1  text-sm font-medium"
                    >
                      Transactions & Invoices
                    </a>
                  </Link>

                  {/* <a
                    href="#"
                    className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1  text-sm font-medium"
                  >
                    Wallet
                  </a> */}
                  <Link href="/app/portal/wallet">
                    <a
                      href="#"
                      className="text-gray-800  hover:text-gray-900 inline-flex items-center  px-1 pt-1  text-sm font-medium"
                    >
                      Wallet / Balance (SGD):
                      <span className="text-green-700 font-medium ml-2">
                        S$ {walletsData?.amount?.toFixed(2) ?? 0}
                      </span>
                    </a>
                  </Link>
                </div>
                {/* <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="focus:outline-none ">
                          <span className="sr-only">Open user menu</span>
                          {/* <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span> */}
                          {user ? (
                            <div className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50 z-50">
                              <div className="flex-shrink-0 h-7 w-7 ">
                                <span className="inline-block h-7 w-7 rounded-full overflow-hidden bg-gray-100">
                                  <svg
                                    className="h-full w-full text-gray-300  "
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                  </svg>
                                </span>
                              </div>
                              <div className="ml-3">
                                <div className="text-sm font-medium text-gray-900">
                                  {user?.displayName ?? user?.email}
                                </div>
                              </div>
                              <svg
                                className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          ) : router.pathname !== "/login" ? (
                            <Link href="/login">
                              <div className=" mt-2 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-semibold rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                Login
                              </div>
                            </Link>
                          ) : (
                            ""
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <Link href="/">
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                                  )}
                                >
                                  Visit website
                                </a>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link href="/change-password">
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                                  )}
                                >
                                  Change Password
                                </a>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                                )}
                                onClick={() => fuego.auth().signOut()}
                              >
                                Logout
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Link href="/app/portal/orders">
                <a
                  href="#"
                  className=" border-primary-500 text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-sm font-medium"
                >
                  Bookings
                </a>
              </Link>
              <a
                href="/app/portal/transactions"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-sm font-medium"
              >
                Transactions
              </a>
              {/*
              <a
                href="#"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-sm font-medium"
              >
                Invoices
              </a>
              {/* 
              <a
                href="#"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Wallet
              </a>
              <a
                href="#"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Balance :
                <span className="text-green-700 font-medium ml-2">$245.98</span>
              </a> */}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <span className="inline-block h-8 w-8 mt-1 rounded-full overflow-hidden bg-gray-100">
                    <svg
                      className="h-full w-full text-gray-300  "
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium text-gray-800">
                    {user?.displayName}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user?.email}
                  </div>
                </div>
                <button className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6 hidden" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1">
                <Link href="/">
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    Visit Website
                  </a>
                </Link>
                <a
                  href="#"
                  onClick={() => fuego.auth().signOut()}
                  className="block px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  Logout
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
