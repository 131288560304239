import staffEmails from "@/config/staffEmails";
import allAgencies from "@/config/allAgencies";
import { useRouter } from "next/router";
import useOrganisation from "@/scripts/hooks/useOrganisation";

export default function useRedirectPath(userProfile) {
  const { user, profile, loading } = userProfile;
  
  const { userOrganisation } = useOrganisation(userProfile);
  const userRole = userOrganisation?.organisationType;
  const router = useRouter();
  const currentPath = router.asPath;
  const isAllowed = () => {
    const arr = currentPath.split("/");
    if (
      currentPath.endsWith("/home") ||
      (arr.length >= 5 && arr[3] === "settings")
    )
      return true;
    return false;
  };
  if (loading) {
    return currentPath;
  } else {
    if (currentPath.startsWith("/login") || currentPath.startsWith("/signup")) {
      if (user) {
        if (staffEmails.includes(user?.email)) {
          return "/admin";
        }  
        else if (profile?.organisationId) {
          return `/app/${profile.organisationId}/home`
        }
          else {
          return "/app";
        }
      }
    } else if (currentPath.startsWith("/app")) {
      if (!user) {
        return "/login";
      } else if (profile && userRole === "reseller") {
        if (currentPath.includes("/portal")) {
          return currentPath;
        } else if (currentPath.includes("/bookings")) {
          return "/app/portal/orders";
        }
        return "/app/portal";
        // else if (allAgencies.includes(profile?.organisationId)) {
        //   if (currentPath.includes("/products")) {
        //     if (currentPath.split("/").length > 3) {
        //       return currentPath;
        //     } else {
        //       return "/app/agency/products";
        //     }
        //   } else if (currentPath.includes("/org")) {
        //     return "/app/agency/org";
        //   } else {
        //     return "/app/agency";
        //   }
        // }
      } else if (!staffEmails.includes(user?.email)) {
        if (profile && !profile?.organisationId) {
          return "/app/pending";
        }
        // else if (
        //   !user.emailVerified && 
        //   !isAllowed()) {
        //   return "/app";
        // }
      } else if (staffEmails.includes(user?.email)) {
        return "/admin";
      } else if (
        profile?.organisationId &&
        profile.organisationId !== router.query.organisation
      ) {
        return `/app/${profile.organisationId}/home`;
      } else if (profile && !profile?.organisationId) {
        return "/app/pending";
      }
    } else if (currentPath.startsWith("/admin")) {
      if (!user) {
        return "/login";
      } else if (!staffEmails.includes(user?.email)) {
        return `/app`;
      }
    } else if (currentPath.startsWith("/shop")) {
      if (currentPath.endsWith("/login") || currentPath.endsWith("/signup")) {
        if (user) {
          return "/shop";
        }
      } else if (
        currentPath.endsWith("/orders") ||
        currentPath.endsWith("/book") ||
        currentPath.endsWith("/confirmation") ||
        currentPath.endsWith("/success") ||
        currentPath.endsWith("/failed") ||
        currentPath.startsWith("/shop/orders")
      ) {
        if (!user) {
          return "/shop";
        }
      }
    }
    return currentPath;
  }
}
