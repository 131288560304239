import { useState, useEffect, useRef } from "react";
import { fuego } from "@nandorojo/swr-firestore";
import Link from "next/link";
import { useRouter } from "next/router";
import useUserProfile from "@/scripts/hooks/useUserProfile";
import _ from "lodash";

export default function AdminLayout({ children }) {
  const [state, setState] = useState({
    mobileMenuOpen: false,
    userDropdownOpen: false,
    sidebarSearchText: ""
  });
  const router = useRouter();
  const { user } = useUserProfile();
  const topRef = useRef(null);

  useEffect(() => {
    if (
      process.browser &&
      window &&
      topRef.current &&
      router.pathname != "/admin/products/edit/[id]"
    ) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [router.asPath, topRef]);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      {state.mobileMenuOpen && (
        <div className="lg:hidden">
          <div className="fixed inset-0 flex z-40">
            {/*
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      */}
            <div className="fixed inset-0">
              <div
                className="absolute inset-0 bg-gray-600 opacity-75"
                aria-hidden="true"
              />
            </div>
            {/*
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      */}
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-700">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() =>
                    setState(state => ({ ...state, mobileMenuOpen: false }))
                  }
                >
                  <span className="sr-only">Close sidebar</span>
                  {/* Heroicon name: x */}
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-shrink-0 flex items-center px-4">
                <div className="font-cabin text-2xl font-bold text-white flex gap-2">
                  <div>
                    twurs:
                    <span className="text-gray-100 font-normal">admin</span>
                  </div>
                </div>
              </div>
              <nav
                className="mt-5 flex-shrink-0 h-full divide-y divide-gray-800 overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="px-2 space-y-1">
                  <AllMenuLinks {...{ state, setState }} />
                </div>
                <div className="pt-6">
                  <div className="px-2 space-y-1">
                    <AllSettingsLinks {...{ state, setState }} />
                  </div>
                </div>
              </nav>
            </div>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </div>
      )}
      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow bg-gray-700 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Link href="/admin">
                <a className="font-cabin text-2xl font-bold text-white flex gap-2">
                  <div>
                    twurs:
                    <span className="text-gray-100 font-normal">admin</span>
                  </div>
                </a>
              </Link>
            </div>
            <nav
              className="mt-5 flex-1 flex flex-col divide-gray-600 divide-y overflow-y-auto px-2"
              aria-label="Sidebar"
            >
              <div className="space-y-1 pb-6">
                {/* Current: "bg-gray-800 text-white", Default: "text-gray-100 hover:text-white hover:bg-gray-600" */}
                <AllMenuLinks {...{ state, setState }} />
              </div>

              <div className="pt-6">
                <div className="space-y-1">
                  <AllSettingsLinks {...{ state, setState }} />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex-1  focus:outline-none overflow-auto">
        <div ref={topRef} />
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
          <button
            className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 lg:hidden"
            onClick={() =>
              setState(state => ({ ...state, mobileMenuOpen: true }))
            }
          >
            <span className="sr-only">Open sidebar</span>
            {/* Heroicon name: menu-alt-1 */}
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </button>
          {/* Search bar */}
          <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-screen-xl lg:mx-auto lg:px-8">
            <div className="flex-1 flex" />
            <div className="ml-4 flex items-center md:ml-6">
              <div className="ml-3 relative">
                <div>
                  <button
                    className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
                    id="user-menu"
                    aria-haspopup="true"
                    onClick={() =>
                      setState({
                        ...state,
                        userDropdownOpen: !state.userDropdownOpen
                      })
                    }
                  >
                    <span className="hidden ml-3 text-gray-700 text-base font-medium lg:block">
                      <span className="sr-only">Open user menu for </span>
                      {user?.email}
                    </span>
                    {/* Heroicon name: chevron-down */}
                    <svg
                      className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                {state.userDropdownOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <Link href="/">
                      <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Visit Landing
                      </a>
                    </Link>

                    <a
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => fuego.auth().signOut()}
                    >
                      Logout
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto -mt-16 pt-16 min-h-screen">
          {children}
        </main>
      </div>
    </div>
  );
}

const MenuLinkItem = props => {
  const { href, svgPath, label, state, setState } = props;
  const router = useRouter();
  return (
    <Link href={href}>
      <a
        className={`${
          router.pathname.startsWith(href)
            ? "bg-gray-800 text-white"
            : "text-gray-100 hover:text-white hover:bg-gray-600"
        }  group flex items-center px-2 py-2 text-base font-medium rounded-md`}
        onClick={() => setState(state => ({ ...state, mobileMenuOpen: false }))}
      >
        <svg
          className={`${
            router.pathname.startsWith(href) ? "text-white" : "text-gray-200"
          }  mr-4 h-6 w-6`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d={svgPath}
          />
        </svg>
        {label}
      </a>
    </Link>
  );
};
// domain

const AllMenuLinks = ({ state, setState }) => {
  return (
    <>
      <MenuLinkItem
        href={"/admin/home"}
        svgPath={
          "M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        }
        label={"Home"}
        {...{ state, setState }}
      />

      <MenuLinkItem
        href={"/admin/users"}
        svgPath={
          "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        }
        label={"Users"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={"/admin/organisations"}
        svgPath={
          "M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
        }
        label={"Suppliers"}
        {...{ state, setState }}
      />

      <MenuLinkItem
        href={"/admin/resellers"}
        svgPath={
          "M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
        }
        label={"Resellers"}
        {...{ state, setState }}
      />

      <MenuLinkItem
        href={"/admin/products"}
        svgPath={"M13 10V3L4 14h7v7l9-11h-7z"}
        label={"Products"}
        {...{ state, setState }}
      />

      <MenuLinkItem
        href={"/admin/promoCode"}
        svgPath={
          "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
        }
        label={"Promo Codes"}
        {...{ state, setState }}
      />

      <MenuLinkItem
        href={"/admin/bookings"}
        svgPath={"M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"}
        label={"Bookings"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={"/admin/finances"}
        svgPath={
          "M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
        }
        label={"Finance"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={"/admin/wallets"}
        svgPath={
          "M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
        }
        label={"Wallets"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={"/admin/analytics"}
        svgPath={
          "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
        }
        label={"Analytics"}
        {...{ state, setState }}
      />
    </>
  );
};

const AllSettingsLinks = ({ state, setState }) => {
  return (
    <>
      <MenuLinkItem
        href={"/admin/logs"}
        svgPath={
          "M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        }
        label={"Logs"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={"/admin/markup"}
        svgPath={
          "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        }
        label={"Markup"}
        {...{ state, setState }}
      />
      {/* <MenuLinkItem
        href={"/admin/test"}
        svgPath={
          "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        }
        label={"Test"}
        {...{ state, setState }}
      /> */}
      {/* <MenuLinkItem
        href={
          "https://drive.google.com/drive/folders/1mACN3YYUNPe659hSAHUkL3h4N3M49f18"
        }
        svgPath={
          "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        }
        label={"Settings"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={
          "https://drive.google.com/drive/folders/1mACN3YYUNPe659hSAHUkL3h4N3M49f18"
        }
        svgPath={
          "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
        }
        label={"Reference"}
        {...{ state, setState }}
      /> */}
      <MenuLinkItem
        href={"https://twurs.slack.com"}
        svgPath={"M7 20l4-16m2 16l4-16M6 9h14M4 15h14"}
        label={"Slack Channel"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={"/change-password"}
        svgPath={"M7 20l4-16m2 16l4-16M6 9h14M4 15h14"}
        label={"Change Password"}
        {...{ state, setState }}
      />
      {/* <MenuLinkItem
        href={"https://cms.twurs.com"}
        svgPath={
          "M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        }
        label={"CMS"}
        {...{ state, setState }}
      /> */}
      <a
        className={`text-gray-100 hover:text-white hover:bg-gray-600 group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer`}
        onClick={() => fuego.auth().signOut()}
      >
        <svg
          className={`text-gray-200 mr-4 h-6 w-6`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
        Logout
      </a>
    </>
  );
};
