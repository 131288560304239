import { useState, useEffect, useRef } from "react";
import { fuego } from "@nandorojo/swr-firestore";
import Link from "next/link";
import { useRouter } from "next/router";
import useUserProfile from "@/scripts/hooks/useUserProfile";
import _ from "lodash";

export default function PortalLayout({ children }) {
  const [state, setState] = useState({
    mobileMenuOpen: false,
    userDropdownOpen: false,
    sidebarSearchText: ""
  });
  const router = useRouter();
  const { user } = useUserProfile();
  const topRef = useRef(null);

  useEffect(() => {
    if (process.browser && window && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [router.asPath, topRef]);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      {state.mobileMenuOpen && (
        <div className="lg:hidden">
          <div className="fixed inset-0 flex z-40">
            {/*
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      */}
            <div className="fixed inset-0">
              <div
                className="absolute inset-0 bg-gray-600 opacity-75"
                aria-hidden="true"
              />
            </div>
            {/*
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      */}
            
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </div>
      )}
      {/* Static sidebar for desktop */}
      <div className="flex-1 overflow-auto focus:outline-none">
        <div ref={topRef} />
        <div className="relative z-10  flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
          <button
            className="px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 lg:hidden"
            onClick={() =>
              setState(state => ({ ...state, mobileMenuOpen: true }))
            }
          >
            <span className="sr-only">Open sidebar</span>
            {/* Heroicon name: menu-alt-1 */}
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </button>
          {/* Search bar */}
          <div className="h-16 flex-1 px-4 flex justify-between  sm:px-6 lg:max-w-screen-xl lg:mx-auto lg:px-8 ">
            <div className="ml-4 mt-3 right-8 flex items-center absolute md:ml-6  ">
              <div className="ml-3 relative">
                <div>
                  <button
                    className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
                    id="user-menu"
                    aria-haspopup="true"
                    onClick={() =>
                      setState({
                        ...state,
                        userDropdownOpen: !state.userDropdownOpen
                      })
                    }
                  >
                    <span className="hidden ml-3 text-gray-700 text-base font-medium lg:block">
                      <span className="sr-only">Open user menu for </span>
                      {user?.email}
                    </span>
                    {/* Heroicon name: chevron-down */}
                    <svg
                      className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                {state.userDropdownOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <Link href="/">
                      <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Website
                      </a>
                    </Link>

                    <a
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => fuego.auth().signOut()}
                    >
                      Logout
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto -mt-16 pt-16 min-h-screen">
          {children}
        </main>
      </div>
    </div>
  );
}

