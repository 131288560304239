import { useDocument } from "@nandorojo/swr-firestore";

export default function useOrganisation(userProfile) {
  const { profile } = userProfile;
  const {
    data: userOrganisation,
  } = useDocument<any>(`organisations/${profile?.organisationId || ""}`, {
    listen: true
  });


  return {
    userOrganisation
  };
}
