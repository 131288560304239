export default {
  excludedPaths: [
    "/apply",
    "/app",
    "/signup",
    "/admin",
    "/distributors",
    "/shop"
  ]
};
