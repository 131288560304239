import Link from "next/link";

export default function PageLink({ className = "", href, ...props }) {
  return (
    <Link href={href}>
      <a
        className={`inline-flex text-lg sm:text-2xl transition-colors duration-200 focus:ring-2 focus:ring-offset-2 focus:ring-primary-700 focus:outline-none rounded-md ${className}`}
        {...props}
      />
    </Link>
  );
}
