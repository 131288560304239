import Link from "next/link";
import { useState, useEffect } from "react";
import { firebaseApp } from "@/scripts/firebaseApp";

export default function Footer() {
  const [state, setState] = useState({
    language: process.browser
      ? window.localStorage.getItem("language") || "English"
      : "English",
    currency: process.browser
      ? window.localStorage.getItem("currency") || "SGD"
      : "SGD",
    email: "",
    requested: false
  });

  useEffect(() => {
    if (state.language !== window.localStorage.getItem("language")) {
      window.localStorage.setItem("language", state.language);
    }
  }, [state.language]);

  useEffect(() => {
    if (state.currency !== window.localStorage.getItem("currency")) {
      window.localStorage.setItem("currency", state.currency);
    }
  }, [state.currency]);

  return (
    <footer className="bg-white" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-screen-lg lg:max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-8 lg:px-8 ">
        <div className="pb-4 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-1 mx-auto text-center md:text-left md:mx-0 sm:grid-cols-2 gap-8 lg:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/suppliers">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Suppliers
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/resellers">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Resellers
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/product-catalogue">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Product Catalogue
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Support
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/contact">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Contact
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/tutorials">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Tutorials
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/faq">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        FAQs
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/company">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        About Us
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/press">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Press
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/careers">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Careers
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/legal/privacy">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Privacy
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/legal/terms">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Terms
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/legal/disclaimer">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Disclaimer
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/legal/cookies">
                      <a className="text-base text-gray-500 hover:text-gray-900">
                        Cookies
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mx-auto text-center md:text-left md:mx-0 mt-12 xl:mt-0">
            <h3 className="text-sm mb-3 font-semibold text-gray-400 tracking-wider uppercase">
              Contact
            </h3>

            <p className="text-gray-500">66A Pagoda Street, Singapore 059225</p>
            <br />

            <p className="text-gray-500">hello@twurs.com</p>
          </div>
        </div>
        {/* <div className="border-t border-gray-200 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Get in touch with us
            </h3>
            <p className="mt-2 text-base text-gray-500">
              Want to find out more about Twurs? Request a Demo now.
            </p>
          </div>
          <div className="mt-4 sm:flex sm:max-w-md lg:mt-0">
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button className="w-full bg-primary-700 hover:bg-primary-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Request demo
              </button>
            </div>
          </div>
        </div> */}
        <div className="mt-8 border-t border-gray-200 pt-8 ">
          <p className="mt-8 text-base text-center text-gray-400 md:mt-0 md:order-1">
            © {new Date().getFullYear()} Twurs Pte. Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
