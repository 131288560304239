import { Fragment, useState } from "react";
import { fuego } from "@nandorojo/swr-firestore";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { DateTime } from "luxon";

export default function FeedbackModal() {
  const feedbackTypes = [{ name: "general" }, { name: "issue" }];
  const ratingTypes = [
    {
      src: "https://img.icons8.com/emoji/48/000000/persevering-face.png",
      value: 1
    },
    {
      src: "https://img.icons8.com/emoji/48/000000/expressionless-face.png",
      value: 2
    },
    {
      src: "https://img.icons8.com/emoji/50/000000/slightly-smiling-face.png",
      value: 3
    },
    {
      src: "https://img.icons8.com/emoji/50/000000/grinning-face-with-big-eyes--v2.png",
      value: 4
    },
    {
      src: "https://img.icons8.com/emoji/50/000000/smiling-face-with-hearts.png",
      value: 5
    }
  ];

  const defaultState = {
    formData: {
      name: "",
      feedbackType: feedbackTypes[0]["name"],
      rating: "",
      message: ""
    },
    curDate: DateTime.fromJSDate(new Date()).toFormat("y-LL-dd"),
    isModalOpen: false
  };

  const [state, setState] = useState(defaultState);

  const handleChange = e => {
    const { name, value } = e.target;
    setState(state => ({
      ...state,
      formData: {
        ...state.formData,
        [name]: value
      }
    }));
  };
  const feedbackRef = fuego.db.collection("userFeedback");
  const handleSubmit = () => {
    const { name, feedbackType, rating } = state.formData;
    if (name && rating) {
      const docId =
        name.split(" ").join("_") + "__" + feedbackType + "__" + state.curDate;
      feedbackRef.doc(docId).set(state.formData);
      setState(defaultState);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <Transition.Root show={state.isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={state.isModalOpen}
          onClose={value =>
            setState(state => ({
              ...state,
              isModalOpen: value
            }))
          }
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        isModalOpen: !state.isModalOpen
                      }))
                    }
                    className="absolute right-2 top-2 hover:bg-gray-100 rounded-full cursor-pointer p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600 "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div className="text-center mb-8 mt-2">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium  text-gray-900"
                    >
                      We appreciate your feedback!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-xs font-medium text-gray-600">
                        Kindly fill in your name and give us a rating.
                      </p>
                    </div>
                  </div>
                  <label
                    htmlFor="name"
                    className="block mb-4 text-sm font-medium text-gray-800"
                  >
                    Enter your name <span className="text-primary-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={state.formData.name}
                    onChange={handleChange}
                    id="name"
                    className="shadow-sm focus:border-gray-700 focus:ring-gray-700 block w-full sm:text-sm border-gray-200 border-2 rounded"
                  />
                  <RadioGroup
                    className="mt-6"
                    value={state.formData.rating}
                    onChange={value =>
                      setState(state => ({
                        ...state,
                        formData: {
                          ...state.formData,
                          rating: value
                        }
                      }))
                    }
                  >
                    <RadioGroup.Label className="sr-only">
                      Rate your experience
                    </RadioGroup.Label>
                    <p className=" text-sm font-medium text-gray-800 mb-4">
                      Rate your experience{" "}
                      <span className="text-primary-700">*</span>
                    </p>
                    <div className="flex text-center">
                      {ratingTypes.map(type => (
                        <RadioGroup.Option
                          key={type.src}
                          value={type.value}
                          className="relative rounded mr-8  cursor-pointer flex focus:outline-none"
                        >
                          {({ checked }) => (
                            <div>
                              <div className="flex">
                                <RadioGroup.Label as="p">
                                  <img
                                    className="h-8 w-8"
                                    src={type.src}
                                    alt={type.value.toString()}
                                  />
                                </RadioGroup.Label>
                              </div>

                              <div
                                className={classNames(
                                  checked
                                    ? "border-yellow-400"
                                    : "border-transparent",
                                  "absolute -inset-px border-2 rounded-full pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  <RadioGroup
                    className="mt-6"
                    value={state.formData.feedbackType}
                    onChange={value =>
                      setState(state => ({
                        ...state,
                        formData: {
                          ...state.formData,
                          feedbackType: value
                        }
                      }))
                    }
                  >
                    <RadioGroup.Label className="sr-only">
                      Feedback type
                    </RadioGroup.Label>
                    <p className=" text-sm font-medium text-gray-800 mb-4">
                      Feedback type
                    </p>
                    <div className="flex text-center">
                      {feedbackTypes.map(type => (
                        <RadioGroup.Option
                          key={type.name}
                          value={type.name}
                          className="relative bg-gray-100 rounded  w-full px-5 py-2 cursor-pointer flex focus:outline-none border-2"
                        >
                          {({ checked }) => (
                            <div>
                              <div className="flex items-center  justify-between w-full">
                                <div className="flex items-center">
                                  <div className="text-sm capitalize">
                                    <RadioGroup.Label as="p">
                                      {type.name}
                                    </RadioGroup.Label>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={classNames(
                                  checked
                                    ? "border-gray-900 "
                                    : "border-transparent",
                                  "absolute -inset-px border-2 rounded pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  <label
                    htmlFor="message"
                    className="block mb-4 mt-6 text-sm font-medium text-gray-800"
                  >
                    Enter your message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    value={state.formData.message}
                    onChange={handleChange}
                    className="resize-y shadow-sm focus:border-gray-700 focus:ring-gray-700 block sm:text-sm border-gray-200 border-2 rounded w-full"
                  ></textarea>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex h-12 items-center justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                    onClick={handleSubmit}
                  >
                    Send Feedback
                    <span className="ml-2">
                      <img
                        className="h-6 w-6"
                        src="https://img.icons8.com/emoji/48/000000/memo-emoji.png"
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <button
        className="fixed bottom-8 right-8 bg-gray-900 p-2 rounded-full focus:outline-none hover:bg-primary-700 transition duration-200 ease-in-out transform hover:scale-110"
        onClick={() =>
          setState(state => ({
            ...state,
            isModalOpen: !state.isModalOpen
          }))
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          />
        </svg>
      </button>
    </div>
  );
}
