import { useRouter } from "next/router";
import LandingLayout from "@/components/layouts/landing/Layout";
import AdminLayout from "@/components/layouts/admin/Layout";
import AppLayout from "@/components/layouts/app/Layout";
import ShopLayout from "@/components/layouts/shop/Layout";
import PendingLayout from "@/components/layouts/app/PendingLayout";
import navBarConfig from "@/config/navBarConfig";
import FeedbackModal from "../elements/FeedbackModal";
import PortalLayout from "@/components/layouts/app/PortalLayout";

export default function LayoutWrapper({ children }) {
  const router = useRouter();
  const { excludedPaths } = navBarConfig;

  if (!excludedPaths.some(path => router.asPath.startsWith(path))) {
    return (
      <LandingLayout>
        {children}
        <FeedbackModal />
      </LandingLayout>
    );
  } else if (router.asPath.includes("pending")) {
    return (
      <>
        <PendingLayout>{children}</PendingLayout>
      </>
    );
  } else if (router.asPath.startsWith("/admin")) {
    return (
      <>
        <AdminLayout>{children}</AdminLayout>
      </>
    );
  } else if (router.asPath.startsWith("/app/portal")) {
    return (
      <>
        <PortalLayout>{children}</PortalLayout>
      </>
    );
    // else if (router.asPath.startsWith("/app/agency")) {
    //   return (
    //     <>
    //       <AgencyLayout>{children}</AgencyLayout>
    //     </>
    //   );
    // }
  } else if (router.asPath.startsWith("/app")) {
    return (
      <>
        <AppLayout>{children}</AppLayout>
      </>
    );
  } else if (router.asPath.startsWith("/distributors")) {
    return (
      <>
        <LandingLayout>{children}</LandingLayout>
      </>
    );
  } else if (router.asPath.startsWith("/shop")) {
    return (
      <>
        <ShopLayout>
          {children}
          <FeedbackModal />
        </ShopLayout>
      </>
    );
  }

  return <>{children}</>;
}
