import useUserProfile from "@/scripts/hooks/useUserProfile";
import useRedirectPath from "@/scripts/hooks/useRedirectPath";
import { useRouter } from "next/router";
import { MetroSpinner } from "react-spinners-kit";
import { GuardSpinner } from "react-spinners-kit";
import authenticatedPages from "@/config/authenticatedPages";

export default function RedirectWrapper({ children }) {
  const router = useRouter();
  const userProfile = useUserProfile();
  const { loading } = userProfile;
  const redirectPath = useRedirectPath(userProfile);
  if (
    !authenticatedPages.some(authenticatedPage =>
      router.pathname.startsWith(authenticatedPage)
    )
  ) {
    return children;
  }

  if (redirectPath !== router.asPath || loading) {
    if (redirectPath !== router.asPath) {
      router.push(redirectPath);
    }
    return (
      <>
        <div className="fixed w-screen h-screen top-0 flex z-50 bg-gray-200 bg-opacity-50">
          <div className="m-auto">
            <MetroSpinner
              size={75}
              color="#8f0000"
              loading
            />
          </div>
        </div>

        {children}
      </>
    );
  }

  return children;
}
