import Head from "next/head";
import React from "react";
const BaseHeaders = () => {
  return (
    <Head>
      <script src="https://unpkg.com/unfetch/polyfill" />
      {/* <script async src="https://cdn.splitbee.io/sb.js"></script> */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:title" content="Twurs" />
      <meta name="twitter:title" content="Twurs" />
      <meta
        name="description"
        content="Smart solutions for package tour distribution"
      />
      <meta
        property="og:description"
        content="Smart solutions for package tour distribution"
      />
      <meta
        name="twitter:description"
        content="Smart solutions for package tour distribution"
      />
      <meta property="og:image" content="/page-preview-image.jpg" />
      <meta name="twitter:image" content="/page-preview-image.jpg" />
      <meta property="og:locale" content="en" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Twurs" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="Twurs" />
    </Head>
  );
};

export default BaseHeaders;
