import Link from "next/link";
import Logo from "@/components/brand/Logo";

export default function LandingMenu(props) {
  const { state, setState } = props;

  return (
    <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
      <div className="pt-5 pb-6 px-5 space-y-6">
        <div className="flex items-center justify-between pb-4">
          <Link href={"/"}>
            <a
              className="h-8 relative"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  solutionsMenuOpen: false,
                  moreMenuOpen: false
                })
              }
            >
              <Logo className="cursor-pointer h-8" />
            </a>
          </Link>
          <div className="-mr-2">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  solutionsMenuOpen: false,
                  moreMenuOpen: false
                })
              }
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div>
          <nav className="grid gap-y-8">
            <Link href="/suppliers">
              <a
                className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                onClick={() =>
                  setState({
                    ...state,
                    mobileMenuOpen: false,
                    solutionsMenuOpen: false,
                    moreMenuOpen: false
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-0 h-6 w-6 text-primary-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                  />
                </svg>
                <div className="text-base leading-6 font-medium text-gray-900">
                  Suppliers
                </div>
              </a>
            </Link>

            <Link href="/resellers">
              <a
                className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                onClick={() =>
                  setState({
                    ...state,
                    mobileMenuOpen: false,
                    solutionsMenuOpen: false,
                    moreMenuOpen: false
                  })
                }
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-primary-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <div className="text-base leading-6 font-medium text-gray-900">
                  Resellers
                </div>
              </a>
            </Link>
          </nav>
        </div>
      </div>
      <div className="py-6 px-5 space-y-6">
        <div className="grid grid-cols-2 gap-y-4 gap-x-8">
          <Link href="/company">
            <a
              className="lg:hidden text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  solutionsMenuOpen: false,
                  moreMenuOpen: false
                })
              }
            >
              About Us
            </a>
          </Link>
          <Link href="/partners">
            <a
              className="lg:hidden text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  solutionsMenuOpen: false,
                  moreMenuOpen: false
                })
              }
            >
              Partners
            </a>
          </Link>
          <Link href="/tutorials">
            <a
              className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  solutionsMenuOpen: false,
                  moreMenuOpen: false
                })
              }
            >
              Tutorials
            </a>
          </Link>

          <Link href="/contact">
            <a
              className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  solutionsMenuOpen: false,
                  moreMenuOpen: false
                })
              }
            >
              Contact
            </a>
          </Link>
          <Link href="/careers">
            <a
              className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  solutionsMenuOpen: false,
                  moreMenuOpen: false
                })
              }
            >
              Careers
            </a>
          </Link>
          <Link href="/faq">
            <a
              className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  solutionsMenuOpen: false,
                  moreMenuOpen: false
                })
              }
            >
              FAQs
            </a>
          </Link>
        </div>
        <div className="space-y-6">
          <span className="w-full flex rounded-md shadow-sm">
            <Link href="/signup/supplier">
              <a
                className="w-1/2 flex items-center mr-2 justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-indigo active:bg-primary-700 transition ease-in-out duration-150"
                onClick={() =>
                  setState({
                    ...state,
                    mobileMenuOpen: false,
                    solutionsMenuOpen: false,
                    moreMenuOpen: false
                  })
                }
              >
                Sign up as supplier
              </a>
            </Link>
            <Link href="/signup/reseller">
              <a
                className="w-1/2 flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-indigo active:bg-primary-700 transition ease-in-out duration-150"
                onClick={() =>
                  setState({
                    ...state,
                    mobileMenuOpen: false,
                    solutionsMenuOpen: false,
                    moreMenuOpen: false
                  })
                }
              >
                Sign up as reseller
              </a>
            </Link>
          </span>
          <p className="text-center text-base leading-6 font-medium text-gray-500">
            Existing customer?{" "}
            <Link href="/login">
              <a
                className="text-primary-600 hover:text-primary-500 transition ease-in-out duration-150"
                onClick={() =>
                  setState({
                    ...state,
                    mobileMenuOpen: false,
                    solutionsMenuOpen: false,
                    moreMenuOpen: false
                  })
                }
              >
                Login
              </a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
