import { useState, useEffect, useRef } from "react";
import { fuego } from "@nandorojo/swr-firestore";
import Link from "next/link";
import { useRouter } from "next/router";
import useUserProfile from "@/scripts/hooks/useUserProfile";
import _ from "lodash";

export default function AdminLayout({ children }) {
  const [state, setState] = useState({
    mobileMenuOpen: false,
    userDropdownOpen: false,
    sidebarSearchText: "",
    emailSuccessCounter: 0,
    emailFailedCounter: 0
  });
  const router = useRouter();
  const { user } = useUserProfile();
  const topRef = useRef(null);

  useEffect(() => {
    if (process.browser && window && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [router.asPath, topRef]);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      {state.mobileMenuOpen && (
        <div className="lg:hidden">
          <div className="fixed inset-0 flex z-40">
            {/*
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      */}
            <div className="fixed inset-0">
              <div
                className="absolute inset-0 bg-gray-600 opacity-75"
                aria-hidden="true"
              />
            </div>
            {/*
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      */}
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-700">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() =>
                    setState(state => ({ ...state, mobileMenuOpen: false }))
                  }
                >
                  <span className="sr-only">Close sidebar</span>
                  {/* Heroicon name: x */}
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-shrink-0 flex items-center px-4">
                <div className="font-cabin text-2xl font-bold text-white flex gap-2">
                  <div>
                    twurs:
                    <span className="text-gray-100 font-normal">
                      resuppliers
                    </span>
                  </div>
                </div>
              </div>
              <nav
                className="mt-5 flex-shrink-0 h-full divide-y divide-gray-800 overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="px-2 space-y-1">
                  <AllMenuLinks {...{ state, setState }} />
                </div>
                <div className="pt-6">
                  <div className="px-2 space-y-1">
                    <AllSettingsLinks {...{ state, setState }} />
                  </div>
                </div>
              </nav>
            </div>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </div>
      )}
      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow bg-gray-700 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Link href="/app/home">
                <a className="font-cabin text-2xl font-bold text-white flex gap-2">
                  <div>
                    twurs:
                    <span className="text-gray-100 font-normal">suppliers</span>
                  </div>
                </a>
              </Link>
            </div>
            <nav
              className="mt-5 flex-1 flex flex-col divide-gray-600 divide-y overflow-y-auto px-2"
              aria-label="Sidebar"
            >
              <div className="space-y-1 pb-6">
                {/* Current: "bg-gray-800 text-white", Default: "text-gray-100 hover:text-white hover:bg-gray-600" */}
                <AllMenuLinks {...{ state, setState }} />
              </div>

              <div className="pt-6">
                <div className="space-y-1">
                  <AllSettingsLinks {...{ state, setState }} />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-auto focus:outline-none">
        <div ref={topRef} />
        <div className="relative z-10  flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
          <button
            className="px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 lg:hidden"
            onClick={() =>
              setState(state => ({ ...state, mobileMenuOpen: true }))
            }
          >
            <span className="sr-only">Open sidebar</span>
            {/* Heroicon name: menu-alt-1 */}
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </button>
          {/* Search bar */}
          <div className="h-16 flex-1 px-4 flex justify-between  sm:px-6 lg:max-w-screen-xl lg:mx-auto lg:px-8 ">
            <div className="ml-4 mt-3 right-8 flex items-center absolute md:ml-6  ">
              {/* {!user?.emailVerified && (
                <button
                  className="max-w-xs bg-white rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50
                    text-gray-700 font-medium"
                  onClick={async e => {
                    try {
                      await user.sendEmailVerification();
                      setState(state => ({
                        ...state,
                        emailSuccessCounter: state.emailSuccessCounter + 1
                      }));
                    } catch (err) {
                      setState(state => ({
                        ...state,
                        emailFailedCounter: state.emailFailedCounter + 1
                      }));
                    }
                  }}
                >
                  {state.emailSuccessCounter === 0
                    ? "Send Verfication Email"
                    : state.emailSuccessCounter === 1
                    ? "Verfication Email Sent"
                    : "Email Sending Failed"}
                </button>
              )} */}
              <div className="ml-3 relative">
                <div>
                  <button
                    className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
                    id="user-menu"
                    aria-haspopup="true"
                    onClick={() =>
                      setState({
                        ...state,
                        userDropdownOpen: !state.userDropdownOpen
                      })
                    }
                  >
                    <span className="hidden ml-3 text-gray-700 text-base font-medium lg:block">
                      <span className="sr-only">Open user menu for </span>
                      {user?.email}
                    </span>
                    {/* Heroicon name: chevron-down */}
                    <svg
                      className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                {state.userDropdownOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <Link href="/">
                      <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Website
                      </a>
                    </Link>

                    <a
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => fuego.auth().signOut()}
                    >
                      Logout
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto -mt-16 pt-16 min-h-screen">
          {children}
        </main>
      </div>
    </div>
  );
}

const MenuLinkItem = props => {
  const { href, svgPath, label, state, setState } = props;
  const router = useRouter();
  return (
    <Link href={href}>
      <a
        className={`${
          router.asPath.startsWith(href)
            ? "bg-gray-800 text-white"
            : "text-gray-100 hover:text-white hover:bg-gray-600"
        }  group flex items-center px-2 py-2 text-base font-medium rounded-md`}
        onClick={() => setState(state => ({ ...state, mobileMenuOpen: false }))}
      >
        <svg
          className={`${
            router.asPath.startsWith(href) ? "text-white" : "text-gray-200"
          }  mr-4 h-6 w-6`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d={svgPath}
          />
        </svg>
        {label}
      </a>
    </Link>
  );
};

const AllMenuLinks = ({ state, setState }) => {
  const router = useRouter();
  return (
    <>
      <MenuLinkItem
        href={`/app/${router.query.organisation}/home`}
        svgPath={
          "M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        }
        label={"Home"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={`/app/${router.query.organisation}/products`}
        svgPath={
          "M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
        }
        label={"Products"}
        {...{ state, setState }}
      />
      {/* <MenuLinkItem
        href={`/app/${router.query.organisation}/organisation`}
        svgPath={
          "M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
        }
        label={"Organisation"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={`/app/${router.query.organisation}/integrations`}
        svgPath={
          "M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
        }
        label={"Integration"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={`/app/${router.query.organisation}/transactions`}
        svgPath={"M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"}
        label={"Transactions"}
        {...{ state, setState }}
      /> */}
      <MenuLinkItem
        href={`/app/${router.query.organisation}/bookings`}
        svgPath={"M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"}
        label={"Bookings"}
        {...{ state, setState }}
      />
      {/* <MenuLinkItem
        href={`/app/${router.query.organisation}/agency`}
        svgPath={
          "M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        }
        label={"Agencies"}
        {...{ state, setState }}
      /> */}
      <MenuLinkItem
        href={`/app/${router.query.organisation}/logs`}
        svgPath={
          "M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        }
        label={"Logs"}
        {...{ state, setState }}
      />
    </>
  );
};

const AllSettingsLinks = ({ state, setState }) => {
  const { profile } = useUserProfile();
  const router = useRouter();
  return (
    <>
      <MenuLinkItem
        href={`/app/${router.query.organisation}/feedback`}
        svgPath={
          "M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
        }
        label={"Feedback"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={`/app/${router.query.organisation}/settings/${profile?.email}`}
        svgPath={
          "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        }
        label={"Settings"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={`/app/${router.query.organisation}/videos`}
        svgPath={
          "M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        }
        label={"Tutorial Videos"}
        {...{ state, setState }}
      />
      <MenuLinkItem
        href={"/change-password"}
        svgPath={"M7 20l4-16m2 16l4-16M6 9h14M4 15h14"}
        label={"Change Password"}
        {...{ state, setState }}
      />
      <a
        className={`text-gray-100 hover:text-white hover:bg-gray-600 group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer`}
        onClick={() => fuego.auth().signOut()}
      >
        <svg
          className={`text-gray-200 mr-4 h-6 w-6`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
        Logout
      </a>
    </>
  );
};
