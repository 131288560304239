import { useState, useRef, useEffect } from "react";
import PageLink from "@/components/common/PageLink";
import { useRouter } from "next/router";
import Link from "next/link";
import Logo from "@/components/brand/Logo";
import Fuse from "fuse.js";
import useUserProfile from "@/scripts/hooks/useUserProfile";
import { fuego } from "@nandorojo/swr-firestore";
import { useCollection } from "@nandorojo/swr-firestore";

export default function NavBar() {
  const [state, setState] = useState({
    solutionsMenuOpen: false,
    moreMenuOpen: false,
    mobileMenuOpen: false,
    searchModalOpen: false,
    userDropdownOpen: false
  });
  const router = useRouter();
  const searchRef = useRef(null);
  const { user } = useUserProfile();

  // const { data: products } = useCollection<any>(`products`, {
  //   listen: true
  // });

  useEffect(() => {
    if (state.searchModalOpen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [state.searchModalOpen, searchRef.current]);
  return (
    <nav className="bg-white shadow relative z-30">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 ">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link href="/shop">
                <a>
                  <Logo className="h-6" />
                </a>
              </Link>
            </div>
            <div className="hidden sm:ml-10 sm:flex sm:space-x-8">
              {user && router.pathname !== "/shop/[slug]/confirmation" ? (
                <a className="border-primary-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  Travel Products
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {/* <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
              <span className="sr-only">View notifications</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button> */}
            {/* Profile dropdown */}
            <div className="ml-3 hidden">
              <div>
                <button
                  className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  id="user-menu"
                  aria-haspopup="true"
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </button>
              </div>
              {/*
      Profile dropdown panel, show/hide based on dropdown state.

      Entering: "transition ease-out duration-200"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    */}
              <div
                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  Your Profile
                </a>
                <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  Settings
                </a>
                <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  Sign out
                </a>
              </div>
            </div>
          </div>
          <div className="-mr-2 items-center hidden">
            {/* Mobile menu button */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {/* Icon when menu is closed. */}
              {/*
      Heroicon name: menu

      Menu open: "hidden", Menu closed: "block"
    */}
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Icon when menu is open. */}
              {/*
      Heroicon name: x

      Menu open: "block", Menu closed: "hidden"
    */}
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {/* <div className="h-16 flex-1 px-4 flex justify-between sm:px-6 lg:max-w-screen-xl lg:mx-auto lg:px-8 ">
        <div className="ml-4 mt-3 right-8 flex items-center absolute md:ml-6  "> */}
          <div className="mt-2 relative cursor-pointer z-20">
            {user ? (
              <div
                className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50 z-50"
                onClick={() =>
                  setState({
                    ...state,
                    userDropdownOpen: !state.userDropdownOpen
                  })
                }
              >
                <div className="flex-shrink-0 h-7 w-7 ">
                  <span className="inline-block h-7 w-7 rounded-full overflow-hidden bg-gray-100">
                    <svg
                      className="h-full w-full text-gray-300  "
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium text-gray-900">
                    {user?.displayName}
                  </div>
                </div>
                <svg
                  className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ) : router.pathname !== "/shop/login" &&
              router.pathname !== "/shop/[slug]/confirmation" ? (
              <Link href="/shop/login">
                <div className=" mt-2 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-semibold rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  Login
                </div>
              </Link>
            ) : (
              ""
            )}
            {user && state.userDropdownOpen && (
              <div
                className="z-50 origin-top-right absolute font-medium right-0 mt-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <Link href="/">
                  <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-primary-700">
                    Visit website
                  </a>
                </Link>

                <Link href="/shop/orders">
                  <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-primary-700">
                    My Orders
                  </a>
                </Link>

                <a
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer hover:text-primary-700"
                  onClick={() => fuego.auth().signOut()}
                >
                  Logout
                </a>
              </div>
            )}
            {/* </div>
        </div> */}
          </div>
        </div>
      </div>
      {/*
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  */}
    </nav>
  );
}
