import Link from "next/link";
import { useRouter } from "next/router";

export default function LandingMenu(props) {
  const { state, setState } = props;
  const router = useRouter();
  const pathName = router.asPath;

  return (
    <nav className="hidden md:flex space-x-10">
      {!pathName.startsWith("/apply") && (
        <>
          <Link href="/solutions">
            <a
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  moreMenuOpen: false,
                  solutionsMenuOpen: false
                })
              }
              className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              Solutions
            </a>
          </Link>

          <Link href="/suppliers">
            <a
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  moreMenuOpen: false,
                  solutionsMenuOpen: false
                })
              }
              className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              Suppliers
            </a>
          </Link>

          <Link href="/resellers">
            <a
              className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  moreMenuOpen: false,
                  solutionsMenuOpen: false
                })
              }
            >
              Resellers
            </a>
          </Link>

          <Link href="/contact">
            <a
              className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  mobileMenuOpen: false,
                  moreMenuOpen: false,
                  solutionsMenuOpen: false
                })
              }
            >
              Contact Us
            </a>
          </Link>

          <div className="relative hidden">
            {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
            <button
              type="button"
              className="text-gray-500 group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
              onClick={() =>
                setState({
                  ...state,
                  moreMenuOpen: !state.moreMenuOpen,
                  solutionsMenuOpen: false,
                  mobileMenuOpen: false
                })
              }
            >
              <span>More</span>
              <svg
                className="text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {state.moreMenuOpen && (
              <div className="absolute z-50 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg shadow-xs overflow-hidden">
                    <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <Link href="/contact">
                        <a
                          className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          onClick={() =>
                            setState({
                              ...state,
                              mobileMenuOpen: false,
                              moreMenuOpen: false,
                              solutionsMenuOpen: false
                            })
                          }
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-primary-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                          </svg>
                          <div className="space-y-1">
                            <p className="text-base leading-6 font-medium text-gray-900">
                              Contact
                            </p>
                            <p className="text-sm leading-5 text-gray-500">
                              Get all of your questions answered through our
                              contact form or reach out to our support team
                            </p>
                          </div>
                        </a>
                      </Link>
                      <Link href="/careers">
                        <a
                          className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          onClick={() =>
                            setState({
                              ...state,
                              mobileMenuOpen: false,
                              moreMenuOpen: false,
                              solutionsMenuOpen: false
                            })
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 flex-shrink-0 text-primary-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                          <div className="space-y-1">
                            <p className="text-base leading-6 font-medium text-gray-900">
                              Careers
                            </p>
                            <p className="text-sm leading-5 text-gray-500">
                              Find out more about the open positions at Twurs
                            </p>
                          </div>
                        </a>
                      </Link>
                      <Link href="/tutorials">
                        <a
                          className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          onClick={() =>
                            setState({
                              ...state,
                              mobileMenuOpen: false,
                              moreMenuOpen: false,
                              solutionsMenuOpen: false
                            })
                          }
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-primary-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                          </svg>
                          <div className="space-y-1">
                            <p className="text-base leading-6 font-medium text-gray-900">
                              Tutorials
                            </p>
                            <p className="text-sm leading-5 text-gray-500">
                              Learn the best practices on how to use our tool to
                              manage your tour products
                            </p>
                          </div>
                        </a>
                      </Link>
                      <Link href="/faq">
                        <a
                          className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          onClick={() =>
                            setState({
                              ...state,
                              mobileMenuOpen: false,
                              moreMenuOpen: false,
                              solutionsMenuOpen: false
                            })
                          }
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-primary-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <div className="space-y-1">
                            <p className="text-base leading-6 font-medium text-gray-900">
                              FAQs
                            </p>
                            <p className="text-sm leading-5 text-gray-500">
                              Have a question? Check out our frequently asked
                              questions for possible answers
                            </p>
                          </div>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </nav>
  );
}
