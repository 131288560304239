import { useAuthState } from "react-firebase-hooks/auth";
import { fuego, useDocument } from "@nandorojo/swr-firestore";

export default function useUserProfile() {
  const [user, authLoading] = useAuthState(fuego.auth());
  const {
    data: profile,
    loading: userDocumentLoading,
    isValidating: userDocumentIsValidating
  } = useDocument<any>(`users/${user?.email || ""}`, {
    listen: true
  });

  const loading =
    authLoading || (user && (userDocumentLoading || userDocumentIsValidating));

  return {
    user,
    profile,
    loading
  };
}
