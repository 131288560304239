import config from "@/tailwind.config";
export default function Logo(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 774.52 176.11"
      {...props}
    >
      <path
        d="M277.63,134H303l2.07-27.68h-25.2l2.89-35.72-38.41,12-1.9,23.74H222.86L220.59,134h19.63l-2.69,33.66q-1.67,20.86,8.15,33t31.5,12.19a84.71,84.71,0,0,0,10.22-.62,37.44,37.44,0,0,0,9.6-2.48l2.48-28.5a52.57,52.57,0,0,1-5.68,1.14,37,37,0,0,1-4.65.31,16.27,16.27,0,0,1-7.43-1.66,12,12,0,0,1-5.16-4.95,15.88,15.88,0,0,1-1.66-8.47Z"
        transform="translate(-37.94 -53.68)"
        style={{ fill: "#231f20" }}
      />
      <polygon
        points="427.37 52.64 399.56 113.7 381.52 52.64 357.15 52.64 329.81 112.69 311.51 52.64 274.14 52.64 309.66 157.55 340.84 157.55 364.32 105.5 381.52 157.55 412.29 157.55 464.54 52.64 427.37 52.64"
        style={{ fill: "#231f20" }}
      />
      <path
        d="M579.51,106.31l-5,57.42q-.62,9.28-5.06,14.87T557,184.17q-7.44,0-10.74-5t-2.68-13.83l4.95-59.07H510.74l-4.54,61.34q-2.07,21.89,6.81,33.56t27.68,11.67A46.06,46.06,0,0,0,562,208,35.63,35.63,0,0,0,573,199.43l-.95,11.79h36.55l8.26-104.91Z"
        transform="translate(-37.94 -53.68)"
        style={{ fill: "#231f20" }}
      />
      <path
        d="M708.06,104.46a34.66,34.66,0,0,0-6.71-.62,39.14,39.14,0,0,0-21.68,6.4,30.09,30.09,0,0,0-10.4,11.09l-.55-15H633.41q.4,7.44.1,15.08t-.72,15.08l-6,74.75h37.38l4.13-52.66q.83-8.46,4-13.42a17.4,17.4,0,0,1,8.67-7,35.14,35.14,0,0,1,12.49-2.07,37.31,37.31,0,0,1,8.68,1,44.19,44.19,0,0,1,8.67,3.09l2.48-34.07A20.5,20.5,0,0,0,708.06,104.46Z"
        transform="translate(-37.94 -53.68)"
        style={{ fill: "#231f20" }}
      />
      <path
        d="M762.89,212.88a110.09,110.09,0,0,1-26.54-3,98.69,98.69,0,0,1-24.05-9.6l11.77-25.4a72.26,72.26,0,0,0,19.2,9,70,70,0,0,0,20.45,3.2q7.42,0,10.32-2t2.89-4.23q0-3.51-4.12-5.58a54.89,54.89,0,0,0-10.64-3.72q-6.51-1.65-13.84-3.82A65.41,65.41,0,0,1,734.6,162a29.88,29.88,0,0,1-10.33-9.5q-3.91-5.88-3.51-15a28.65,28.65,0,0,1,6.3-17.76,40.32,40.32,0,0,1,16.73-12q10.63-4.33,24.68-4.34a84.14,84.14,0,0,1,23.85,3.51A72.85,72.85,0,0,1,812.46,116L801.1,140.8q-16.73-11.15-33.46-11.15-7,0-10.43,1.76t-3.4,4.44q0,3.51,4,5.68a47.43,47.43,0,0,0,10.43,3.82q6.4,1.65,13.53,3.92a76.31,76.31,0,0,1,13.52,5.78,30.61,30.61,0,0,1,10.43,9.19q4,5.69,4,14.36,0,16.31-12.6,25.29T762.89,212.88Z"
        transform="translate(-37.94 -53.68)"
        style={{ fill: "#231f20" }}
      />
      <path
        d="M121,223.55a15.53,15.53,0,0,1-24.94-.07L52.46,167.22a70.63,70.63,0,1,1,112.19.06Z"
        transform="translate(-37.94 -53.68)"
        style={{ fill: config.theme.colors.primary[600] }}
      />
      <path
        d="M135.2,105.45a5.63,5.63,0,0,0-8,0l-14,14V87.5a5.63,5.63,0,0,0-11.26,0v31.91l-14-14a5.64,5.64,0,0,0-8,8L103.67,137a5.63,5.63,0,0,0,8,0l23.57-23.56A5.64,5.64,0,0,0,135.2,105.45Z"
        transform="translate(-37.94 -53.68)"
        style={{ fill: "#fdfefe" }}
      />
      <path
        d="M136,161.4H80.26a5.64,5.64,0,0,1-5.63-5.64V142.23a5.63,5.63,0,1,1,11.26,0v7.9h44.45v-7.9a5.63,5.63,0,0,1,11.26,0v13.53A5.64,5.64,0,0,1,136,161.4Z"
        transform="translate(-37.94 -53.68)"
        style={{ fill: "#fdfefe" }}
      />
    </svg>
  );
}
