export default [
  {
    title: "Our Pricing",
    url: "/pricing",
    breadcrumbs: ["Pricing"],
    description: "100% risk-free at $0 per month"
  },
  {
    title: "User Guides",
    url: "/guide",
    breadcrumbs: ["Guides"],
    description: "Learn how to use our product"
  },
  {
    title: "Contact Us",
    url: "/contact",
    breadcrumbs: ["Contact"],
    description: "Need help? Contact us here"
  },
  {
    title: "Inventory Management",
    url: "/about#inventory",
    breadcrumbs: ["Solutions", "Inventory"],
    description: "Manage your travel products"
  },
  {
    title: "Supply Analytics",
    url: "/about#analytics",
    breadcrumbs: ["Solutions", "Analytics"],
    description: "Predict supply and demand"
  },
  {
    title: "Distributor Integrations",
    url: "/about#integrations",
    breadcrumbs: ["Solutions", "Integrations"],
    description: "Connect with distribution channels"
  },
  {
    title: "Process Automation",
    url: "/about#automation",
    breadcrumbs: ["Solutions", "Automation"],
    description: "End-to-end product management"
  },
  {
    title: "Contact",
    url: "/support",
    breadcrumbs: ["Contact"],
    description: "Reach out to our support team"
  },
  {
    title: "Frequently Asked Questions",
    url: "/#faqs",
    breadcrumbs: ["FAQs"],
    description: "Have a question? Check our FAQs"
  },
  {
    title: "Operator Solutions",
    url: "/about",
    breadcrumbs: ["Solutions"],
    description: "Our solutions for travel operators"
  },
  {
    title: "Twurs for Distributors",
    url: "/distributors",
    breadcrumbs: ["Distributors"],
    description: "Click here if you are a distributor"
  },
  {
    title: "Home Page",
    url: "/",
    breadcrumbs: ["Home"],
    description: "Distribute your products globally"
  }
];
